<template>
  <div class="BlockPagination">
    <BaseButton
      v-if="previous"
      class="previous-button"
      variation="tertiary"
      @click="changeBlock(previous)"
    >
      {{ $t('BUTTON.CONTROL.PREVIOUS') }}
    </BaseButton>

    <PaginationBullets
      :bullets="blocks.length"
      :current="currentBlock"
    />

    <BaseButton
      v-if="next"
      class="next-button"
      @click="submitBlock(next)"
    >
      {{ $t('BUTTON.CONTROL.NEXT') }}
    </BaseButton>

    <BaseButton
      v-else
      class="finish-button"
      @click="submitBlock(next)"
    >
      {{ $t('BUTTON.CONTROL.FINISH') }}
    </BaseButton>
  </div>
</template>

<script>
import PaginationBullets from '@/components/blocks/PaginationBullets';

const OFFSETS = {
  PREVIOUS: -1,
  NEXT: 1,
};

export default {
  components: {
    PaginationBullets,
  },

  props: {
    blocks: {
      type: Array,
      required: true,
    },

    blockId: {
      type: Number,
      required: true,
    },
  },

  computed: {
    previous() {
      return this.getBlockByOffset(OFFSETS.PREVIOUS);
    },

    next() {
      return this.getBlockByOffset(OFFSETS.NEXT);
    },

    currentBlock() {
      return this.blocks?.findIndex(block => block.id === this.blockId);
    },
  },

  methods: {
    getBlockByOffset(offset) {
      const blockIndex = this.getBlockIndex();
      if (blockIndex < 0) return { previous: undefined, next: undefined };

      return this.getBlockByIndex(blockIndex + offset)?.id;
    },

    getBlockIndex() {
      return this.blocks.findIndex(block => block.id === this.blockId);
    },

    getBlockByIndex(blockIndex) {
      return ((typeof this.blocks[blockIndex] !== 'undefined') ? this.blocks[blockIndex] : undefined);
    },

    changeBlock(blockId) {
      window.scrollTo(0, 0);
      this.$emit('change-block', blockId);
    },

    submitBlock(blockId) {
      window.scrollTo(0, 0);
      this.$emit('submit-block', blockId);
    },
  },
};
</script>

<style lang="scss" scoped>
.BlockPagination{
  border-top: 1px solid $color-neutral-mid;
  background: linear-gradient(45deg, transparent 0%, #fff, transparent);
  padding: $spacing-s;
  display: grid;
  scroll-behavior: smooth;


  align-self: flex-end;

  & > *{
    place-self: center;
  }

  :is(.next-button, .finish-button){
    grid-column: 3;
  }

  .PaginationBullets{
    grid-column: 2;
      @media #{$breakpoint-md-max} {
        display: none;

      }
  }
}
</style>
