var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BlockPagination" },
    [
      _vm.previous
        ? _c(
            "BaseButton",
            {
              staticClass: "previous-button",
              attrs: { variation: "tertiary" },
              on: {
                click: function($event) {
                  return _vm.changeBlock(_vm.previous)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("BUTTON.CONTROL.PREVIOUS")) + " ")]
          )
        : _vm._e(),
      _c("PaginationBullets", {
        attrs: { bullets: _vm.blocks.length, current: _vm.currentBlock }
      }),
      _vm.next
        ? _c(
            "BaseButton",
            {
              staticClass: "next-button",
              on: {
                click: function($event) {
                  return _vm.submitBlock(_vm.next)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("BUTTON.CONTROL.NEXT")) + " ")]
          )
        : _c(
            "BaseButton",
            {
              staticClass: "finish-button",
              on: {
                click: function($event) {
                  return _vm.submitBlock(_vm.next)
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("BUTTON.CONTROL.FINISH")) + " ")]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }