<template>
  <div
    class="BlockQuestion"
    :class="question.template"
  >
    <label
      v-if="!isStatement && hasStatementLabel"
      :for="question.key"
      v-html="statementLabel"
    />

    <component
      :is="questionType"
      :key="question.id"
      v-model="innerValue"
      class="question"
      :class="[{disabled:isDisabled && !isStatement && !allowModifyAnswers}]"
      v-bind="questionAttributes"
      @input="answerSelected(question.id, ($event && $event.target) ? $event.target.value : $event)"
      @save-image="saveImage($event)"
      v-on="$listeners"
    >
      <template #error>
        <slot />
      </template>
    </component>
  </div>
</template>

<script>
import RadioChoices from '@/components/questions/RadioChoices';
import MultipleChoices from '@/components/questions/MultipleChoices';
import RadioBoxes from '@/components/questions/RadioBoxes';
import MultipleBoxes from '@/components/questions/MultipleBoxes';
import RadioChoicesCustom from '@/components/questions/RadioChoicesCustom';
import ValidationButton from '@/components/questions/ValidationButton';
import TableCustom from '@/components/questions/TableCustom';
import SelectSearch from '@/components/questions/SelectSearch';
import GoogleMap from '@/components/questions/GoogleMap';
import UploadFile from '@/components/questions/UploadFile';
import ValorationChoices from '@/components/questions/ValorationChoices';


import Statement from '@/components/statements/Statement';

import ModelMixin from '@/mixins/ModelMixin';

const QUESTIONS_TYPES = {
  input: 'BaseInput',
  text: 'BaseText',
  choice: 'RadioChoices',
  choices: 'MultipleChoices',
  box: 'RadioBoxes',
  boxes: 'MultipleBoxes',
  select: 'BaseSelect',
  choiceCustom: 'RadioChoicesCustom',
  table: 'TableCustom',
  search: 'SelectSearch',
  validation: 'ValidationButton',
  statement: 'Statement',
  map: 'GoogleMap',
  uploadFile: 'UploadFile',
  valorationChoices: 'ValorationChoices',
};

export default {
  components: {
    RadioChoices,
    MultipleChoices,
    RadioBoxes,
    MultipleBoxes,
    RadioChoicesCustom,
    TableCustom,
    SelectSearch,
    ValidationButton,
    Statement,
    GoogleMap,
    UploadFile,
    ValorationChoices,
  },

  mixins: [ModelMixin],

  props: {
    question: {
      type: Object,
      required: true,
    },

    errors: {
      type: Array,
      default: undefined,
    },

    temporary: {
      type: Boolean,
      default: false,
    },

    allowModifyAnswers: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    statementLabel() {
      const i18n = this.question.attributes?.i18n;
      return (i18n && this.$te(`QUESTION.${i18n}.LABEL`)) ? this.$t(`QUESTION.${i18n}.LABEL`) : this.$t(`QUESTION.${this.question.key}.LABEL`);
    },

    hasStatementLabel() {
      // const i18n = this.question.attributes?.i18n;
      // return (i18n && this.$te(`QUESTION.${i18n}.LABEL`)) || (this.$te(`QUESTION.${this.question.key}.LABEL`));
      return true;
    },

    isStatement() {
      return this.question.type === 'statement';
    },

    questionType() {
      return (typeof QUESTIONS_TYPES[this.question.type] !== 'undefined') ?
        QUESTIONS_TYPES[this.question.type] :
        QUESTIONS_TYPES.input;
    },

    questionAttributes() {
      return Object.assign({}, this.question.attributes, { questionId: this.question.id, name: this.question.key, disabled: this.isDisabled });
    },

    isDisabled() {
      const isAnswered = this.innerValue !== undefined;
      if (this.question.type === 'validation') return false;

      return (isAnswered && !this.temporary && !this.allowModifyAnswers);
    },
  },

  methods: {
    answerSelected(questionId, answer) {
      const questionKey = this.question.key;
      this.$emit('answer-selected', { questionId, questionKey, answer });
    },

    saveImage(payload) {
      const { questionKey } = payload;
      const { questionId } = payload;
      const { answer } = payload;
      this.$emit('answer-selected', { questionId, questionKey, answer });
    },
  },
};
</script>

<style lang="scss" scoped>
.BlockQuestion{
  padding: $spacing-2xs $spacing-s;

  label{
    display: block;
    margin-bottom: $spacing-xs;

    &::v-deep{
      b{ font-weight: 600; }
      p{ font-weight: 500; }

      p:not(:last-child){
        margin-bottom: $spacing-xs;
      }
    }

    font-weight: 600;
  }

  .question.disabled{
    pointer-events: none;
    filter: grayscale(50%) opacity(.6);
  }
}
</style>
